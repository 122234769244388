import React, {Component} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {buildBlocks} from "../services/builder";
import {CoreFields} from "./fragment";  // eslint-disable-line
import {CoreBlockFragment} from "../components/blocks/CoreBlock";  // eslint-disable-line

/**
 * This page should query the posts based on the context provided and pass the results to the post-list block
 * No post-list block? Show an error
 */
class ListPosts extends Component {

    render() {
      const blocks = buildBlocks(
        this.props.data.wpPage.blocks, 
        { 
          posts: this.props.data.allWpPost.edges,
          featuredPost: this.props.data.wpPost,
        },
        {
          posts: this.props.data.allWpPost.edges,
          featuredPost: this.props.data.wpPost,
          ...this.props.pageContext
        }
      );
      return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: this.props.data.wpPage.uri
        }}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpPage.title}
        themeOptions={this.props.data.wp.themeOptions}
      >
          {blocks}
      </Layout>
      )
    }
}

export const query = graphql`

query ($id: String, $ids: [Int]) {
    wpPage(slug: {eq: $id}) {
      id
      slug
      title
      uri
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      ... on WpBlockEditorContentNode {
        blocks {
          ...CoreBlock
          ...CoreFields
        }
      }  
    }
    allWpPost(sort: {order: DESC, fields: date}, filter: {databaseId: {in: $ids}, isSticky: {eq: false}}) {
      edges {
        node {
          id
          slug
          uri
          title
          excerpt
          date(formatString: "DD MMMM YYYY")
          featuredImage {
            node {
              mediaItemUrl
              altText
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPost(isSticky: {eq: true}) {
      id
      slug
      uri
      title
      excerpt
      date(formatString: "DD MMMM YYYY")
      featuredImage {
        node {
          mediaItemUrl
          altText
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default ListPosts
